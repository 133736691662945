<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- Descrição -->
      <v-col cols="12">
        <v-text-field
          v-model="deficiencia.descricao"
          ref="descricao"
          label="Descrição"
          placeholder="Digite a descrição da deficiência"
          dense
          outlined
          data-vv-as="descrição"
          persistent-hint
          :hint="errors.first('res_deficiencia.descricao')"
          :error="
            errors.collect('res_deficiencia.descricao').length ? true : false
          "
          v-validate="'required|min:3|max:150'"
          data-vv-scope="res_deficiencia"
          data-vv-validate-on="change"
          id="descricao"
          name="descricao"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DeficienciaForm",
  inject: ["$validator"],
  computed: {
    deficiencia: {
      get() {
        return this.$store.getters["resDeficiencias/getDeficiencia"];
      },
      set(value) {
        this.$store.dispatch("resDeficiencias/setDeficiencia", value);
      },
    },
  },
};
</script>

<style></style>
